$primary: #313F66 !default;
$secondary: #D99D42 !default;
$dark: #292929 !default;
$light: #F2F2F2 !default;
$theme-1: $primary !default;
$theme-2: #D99D42 !default;
$theme-3: #FFDD32 !default;
$theme-colors: (
        'theme-1': $theme-1,
        'theme-2': $theme-2,
        'theme-3': $theme-3
);
$light-accent: lighten($theme-2, 30%);

//========================
// general theme options
//========================
$footer-header-color: $secondary !default;
$card-link-color: $primary !default;
$link-decoration: underline;
$border-radius: 10px; // radius for cards, images and buttons
$line-height-base: 1.6;

//========================
//define text properties
//========================
@import url("https://use.typekit.net/kzm0hmj.css");
$font-family-base: 'canada-type-gibson', sans-serif;
$headings-font-weight: 600;
$display-font-weight: 600;
$font-weight-bold: 600;
$headings-color: $theme-1;

//========================
//define button properties
//========================
$btn-font-weight: 300;
$btn-border-radius: $border-radius;
$btn-border-radius-lg: $border-radius;
$btn-border-radius-sm: $border-radius;
$btn-footer-style: theme-2;

//========================
//define banner spots properties
//========================
$accordion-banner-bg-color: theme-1; // Ect: theme-1, primary, white
$accordion-banner-text-color: white;

//========================

@import '../../../../src/scss/main.scss';

$display-font-weight: 600 !default;
$opacity-3: 0.3;

@import '@/src/scss/components/hero-video.scss';

html, body {
  background-color: #f6f6f6;
  overflow-x: clip;
}

.row--quickbanner {
  max-width:900px;
  margin-top: -6rem;
}

.mobile--aspect-img {
  @include media-breakpoint-down(sm) {
    .flavour-stripe {
      width: 50%;
    }
    .card-img-top {
      aspect-ratio: 2.1;
      object-fit: cover;
    }
  }
}

.flavour--img {
  img {
    max-height: 120px;
    @include media-breakpoint-up(md) {
      max-height: 170px;
    }
  }
}

.flavour--img-2 {
  img {
    max-height: 120px;
    @include media-breakpoint-up(md) {
      max-height: 220px;
    }
  }
}

.opacity-3 {
  opacity: $opacity-3;
}

.flavour-bg--img {
   img {
     opacity: $opacity-3;
     max-height: 280px;
     @include media-breakpoint-up(md) {
       max-height: 330px;
     }
   }
 }

.rounded-lg {
  border-radius: $border-radius !important;
}

.rounded-top {
  border-radius: $border-radius $border-radius 0 0 !important;
}

.btn {
  &.btn--footer {
    @extend .btn-#{$btn-footer-style}
  }
}

.banner-max {
  object-fit: cover;
  min-height: 450px;
  max-height: 550px;
  @include media-breakpoint-down(sm) {
    min-height: 300px;
    max-height: 400px;
  }
}

.section--recommended {
  @include media-breakpoint-down(md) {
    background: none !important;
  }
}


.navbar--menu {
  .navbar-collapse {
    @include media-breakpoint-down(md) {
      .nav-item {
        margin-left: 0 !important;
      }
      background-color: $theme-1;
      margin-top: 1rem;
      .nav-link {
        color: white !important;
      }
    }
  }
  .nav-item {
    .dropdown-menu {
      @extend .border-secondary;
      a {
        @extend .text-primary;
      }
      @include media-breakpoint-down(md) {
        background: none !important;
        a {
          color: white !important;
        }
      }
    }
  }
}

.navbar-brand {
  @extend .my-auto;
}

.navbar-brand--img {
  height: 40px;
  @include media-breakpoint-up(lg) {
    height: 60px;
  }
}

.float--bar {
  @extend .top-0;
  @extend .bg-white;
  @extend .position-absolute;
  right: -900px !important;
  width: 1000px !important;
}

.nav-link, .topbar-card--link {
  i {
    @extend .mx-1;
  }
}

.dropdown-submenu {
  .dropdown-menu {
    @extend .p-0;
    border-radius: 0 !important;
  }
}

.topbar-card--link {
  .badge {
    @extend .float-right;
    @extend .badge-success;
    @extend .text-white;
  }
}

.footer-menu--item {
  font-weight: 400 !important;
  text-decoration: underline;
}


.card-title {
  @extend .mb-2;
}

.card--item {
  .card {
    background-color: $light;
    @extend .border-0;
    .float-right {
      float: none !important;
      .fa {
        display: none;
      }
    }
  }
  .info-bar--background {
    @extend .text-theme-2;
    @extend .pb-0;
    @extend .mb-0;
    @extend .pt-3;
  }
  .card-body {
    @extend .pt-2;
  }
}

.thead-light {
  th {
    background-color: #{$light-accent} !important;
    @extend .border-0;
  }
  tr {
    th:first-child {
      border-radius: $border-radius 0 0 0;
    }
    th:last-child {
      border-radius: 0 $border-radius 0 0;
    }
  }
}

.accordion {
  .card-header, button {
    @extend .bg-#{$accordion-banner-bg-color};
    @extend .text-#{$accordion-banner-text-color};
  }
}

.top-menu {
  @extend .py-lg-2;
  a {
    @extend .text-white;
    @extend .text-decoration-none;
  }
}

.container--menu {
  .navbar-nav {
    .nav-item {
      @extend .text-white;
    }
  }
}

.main-headerless {
  @extend .pt-4;
}

.header--border {
  @extend .border;
  @extend .border-light;
}

.wrapper {
  @extend .mt-3;
}

.container--menu {
  @extend .my-3;
  @extend .p-0;
  @extend .my-lg-0;
  @extend .justify-content-end;
  .navbar--menu {
    @extend .p-0;
    @extend .pb-lg-2;
  }
  .shoppingcart--icon {
    @extend .mr-2;
    @extend .text-white;
    @include media-breakpoint-down(md) {
      position: relative;
      .badge {
        position: absolute;
        right: -13px;
        top: -11px;
      }
    }
    i {
      font-size: 1em;
    }
  }
  .top-menu, ul {
    @extend .m-0;
    @extend .ml-auto;
    @extend .d-flex;
    @extend .pr-lg-2;
    .nav-item {
      @extend .my-auto;
      @extend .ml-2;
      @extend .d-block;
    }
  }
  .nav-search {
    .nav-link {
      @extend .px-1;
      @extend .text-white;
      i {
        font-size: 1em;
      }
    }
  }
}

.highlight--cta {
  height: 250px;
  @include media-breakpoint-up(md) {
    height: 400px;
  }
}

#rooster, #Rooster, .rooster {
  .thead-light {
    th {
      background: none !important;
      border-bottom: solid 2px $theme-2 !important;
      color: $theme-1;
    }
  }
  th {
    font-weight: 400;
  }
}

.title--page {
  @extend .d-none;
}

.flavor-shape {
  @extend .w-100;
  max-height: 140px;
  aspect-ratio: 8.1;
  margin-bottom: -1px;
  margin-top: -1px;
}

.agenda--home {
  h2 {
    @extend .text-center;
    @extend .mb-4;
  }
}

.account-dropdown--style, .language-selector {
  @extend .py-0;
}

.card-stretch-hover {
  .card-img-top {
    transition: transform .3s;
    @extend .w-100;
    @extend .h-auto;
  }
  .card-img-bg {
    object-fit: cover;
    transition: transform .3s;
    @extend .h-100;
    @extend .w-100;
  }
  &:hover {
    .card-img-top, .card-img-bg {
      transform: scale(1.1);
    }
  }
}

.display-5 {
  font-weight: $display-font-weight !important;
}

.bg-gradient {
  background: linear-gradient(0deg, rgba(0, 114, 227, 0.40) 30%, $theme-1 70%);
  @include media-breakpoint-up(md) {
    background: linear-gradient(-90deg, rgba(0, 114, 227, 0.40) 30%, $theme-1 70%);
  }
}

footer {
  @extend .z-1;
  a {
    @extend .text-white;
    &:hover {
      @extend .text-theme-2;
    }
  }
}

.stretched-link::after {
  z-index: 999;
}

.watermark {
  opacity: 1 !important;
}

.highlight-banners-wrapper {
  .highlight-section {
    background: $theme-3;
    position: absolute;
    width: 100%;
    height: 165px;
    bottom: -135px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    z-index: 0;
    object-fit: cover;
    margin-bottom: 100px;
  }

  .highlight-card-top-img {
    width: 100%;
    height: 100% !important;
    position: relative;
    transition: height .8s;
    object-fit: cover;
  }

  .highlight-card-body {
    @extend .p-3;
    height: 200px;
    transition: all .8s;
    @extend .bottom-0;
    width: 100%;
    @extend .position-absolute;
  }

  .highlight-read-on {
    @extend .d-none;
  }

  .highlight-card-hover-grad {
    background: linear-gradient(0deg,  rgba(255,255,255,0.8), rgba(255,255,255,0));
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    opacity: 1;
    transition: opacity .3s ease-in-out;
    bottom: 0;
  }

  .highlight-card {
    border-radius: $border-radius;
    display: inline-block;
    outline: 0;
    @extend .overflow-hidden;
    z-index: 1;
    height: 100%;
    width: 100%;
    position: relative;
    highlight-card-body {
      transition: all .4s ease-in;
      @extend .position-absolute;
      h5 {
        color: $dark;
      }
    }
    &:hover {
      .highlight-card-top-img {
        height: 100% !important;
      }
      .highlight-card-body {
        transform: translateY(-20px);
        z-index: 2;
        background: none;
        h5 ,a {
          color: white !important;
        }
      }
      .highlight-card-hover-grad {
        background: $secondary;
        opacity: .8;
      }
      .highlight-read-on {
        display: block;
      }
    }
  }
}

.flavor-shape-footer {
  margin-top: -40px;
}

 .flavour-stripe {
  @extend .position-absolute;
  @extend .left-0;
  @extend .bottom-0;
  max-width: 380px !important;
  margin-bottom:10px;
}

.border-right--separator {
  @include media-breakpoint-up(md) {
    border-right: solid 2px $primary;
  }
}

.hero-cta {
  margin-top: 9em;
  @include media-breakpoint-down(md) {
    margin-top: 5em;
  }
}
.hero-general-cta {
  @include media-breakpoint-up(md) {
    margin-top: 2.3em;
  }
}

.cta--bottom {
  .btn {
    @include media-breakpoint-down(sm) {
      width: 100%;
      margin-top: 0.5rem;
    }
  }
}

.btn-secondary, .btn-theme-2 {
  @extend .text-white;
}

.photo-banner {
  @extend .object-fit-cover;
  @extend .mx-auto;
  @extend .w-100;
}

.hero--banner {
  margin-top: -122px;
  .photo-banner {
    @include media-breakpoint-down(sm) {
      min-height: 200px;
      max-height: 350px;
    }
  }
}

.general--banner {
  margin-top: -122px;
  .photo-banner {
    min-height: 200px;
    @include media-breakpoint-down(sm) {
      min-height: 100px;
    }
  }
}

.menu--main {
  @extend .bg-primary;
}

#main-content, main {
  min-height: 55vh;
  display: inline-table;
  table {
    @extend .table;
  }
}

.rental-scheduler {
  @extend .w-100;
}

hr {
  border-top: solid 1px #2c2c2c;
}

.breadcrumb--bar {
  border-bottom: solid 2px $theme-2;
  border-radius: $border-radius $border-radius 0 0;
  a {
    text-decoration: none;
  }
}

.part-category-nav {
  .list-group-item {
    background: none;
  }
  a {
    text-decoration: none;
  }
}

.ticket-list-item--inner {
  .card-subtitle {
    font-weight: 400;
    @extend .mt-0;
  }
  .img-wrapper {
    img {
      @extend .rounded;
    }
  }
  background: none;
  @extend .border-0;
  .ticket-list-item--inner-spacing {
    @extend .px-0;
    .btn {
      @extend .btn-block;
    }
  }
}

.bold {
  font-weight: 600 !important;
}

.mobile-hamburger-color {
  @extend .text-white;
}

.hamburger-inner, .hamburger-inner:before, .hamburger-inner:after {
  @extend .bg-white;
}

.hamburger--squeeze {
  @extend .pr-1;
}

.wrapper {
  overflow: unset !important;
}

.sidebar {
  @extend .pt-3;
  margin-top: -20px;
  min-width: 500px;
  max-width: 500px;
  background: $primary;
  transition: all .3s;
  position: relative;
  @extend .z-1;
  margin-bottom: -3rem;
  h2 {
    @extend .text-white;
  }
  .invalid-feedback, .was-validated {
    color: $theme-3;
  }
  @include media-breakpoint-down(md) {
    min-width: 100%;
    min-height: unset;
  }
  .sidebar-nav {
    padding: 0 6rem;
    @include media-breakpoint-down(md) {
      padding: 0 1rem;
    }
    .list-group-item {
      &.active {
        background: $secondary;
        border-color: $secondary;
        color: $white;
      }
    }
  }
}

